import React from "react";
//import UnderConstruction from "./components/UnderConstruction";
import RouteSwitch from "./components/v1/RouteSwitch";
//import RouteSwitch from "./components/v2/RouteSwitch";

//import logo from "./assets/betongeometria.png";

const App = () => {
  return (
    // <UnderConstruction />
    <RouteSwitch />
  );
};

export default App;
